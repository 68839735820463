<template>
  <BaseCard class="tw-my-4">
    <div class="tw-p-6">
      <div v-if="isLoading || isStudioPlusCampaignsLoading || isStudioPlusCampaignsFetching">
        <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-80" aria-hidden="true"></div>
      </div>

      <div v-else-if="isStudioPlusCampaignsError" class="tw-text-red-700" v-html="$t('errors.wrong_data_from_app')" />

      <div v-else-if="!Array.isArray(subscriptions) || !subscriptions.length">
        {{ $t('subscriptions.empty') }}
      </div>

      <div v-else>
        <ul
          v-if="subscriptionsByStatus.active.length > 0"
          class="tw-flex tw-flex-col tw-gap-4 tw-mb-4 tw-border-gray-300"
        >
          <div class="tw-font-bold tw-text-xl">{{ $t('active_subscriptions_header') }}</div>
          <li
            v-for="(activeSubscription, index) in subscriptionsByStatus.active"
            :key="index"
            class="tw-mb-2 tw-p-4 tw-bg-gray-100 tw-rounded-lg"
          >
            <SubscriptionsItemPreview
              :accommodationName="activeSubscription.accommodationName"
              :subscription="activeSubscription"
              :accommodationId="activeSubscription.accommodationId"
            />
          </li>
        </ul>
        <ul v-if="subscriptionsByStatus.expired.length > 0" class="tw-flex tw-flex-col tw-gap-4 tw-mb-4">
          <div class="tw-font-bold tw-text-xl">{{ $t('expired_subscriptions_header') }}</div>
          <li
            v-for="(expiredSubscription, index) in subscriptionsByStatus.expired"
            :key="index"
            class="tw-mb-2 tw-p-4 tw-bg-gray-100 tw-rounded-lg"
          >
            <SubscriptionsItemPreview
              :accommodationName="expiredSubscription.accommodationName"
              :subscription="expiredSubscription"
              :accommodationId="expiredSubscription.accommodationId"
            />
          </li>
        </ul>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import SubscriptionsItemPreview from '@/components/settings/subscriptions/SubscriptionsItemPreview.vue'

import { useStudioPlusCampaigns } from '@/components/settings/subscriptions/queries'
import { useAccommodationsByOrgId } from '@/layouts/queries'

import useToastNotifications from '@/components/notifications/useToastNotifications'

const route = useRoute()
const store = useStore()

const selectedOrganisationId = computed(() => store.state?.session?.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const { studioPlusCampaigns, isStudioPlusCampaignsLoading, isStudioPlusCampaignsError, isStudioPlusCampaignsFetching } =
  useStudioPlusCampaigns(selectedOrganisationId)
const { displayNotification } = useToastNotifications()

const getStatus = subscription => {
  if (subscription.type === 'RATE_CONNECT') {
    return subscription.status?.toLowerCase() === 'inactive' ? 'expired' : 'active'
  } else {
    if (subscription.endDate === null) {
      return 'active'
    } else if (new Date() > new Date(subscription.endDate)) {
      return 'expired'
    } else {
      return 'canceled'
    }
  }
}

const subscriptions = computed(() => {
  return studioPlusCampaigns.value?.map(item => {
    const currentAccommodation = accommodationsData.value.find(
      accommodation => accommodation.accommodationId === item.itemId
    )

    return {
      hasActiveRC: accommodationHasRateConnect(item),
      accommodationId: currentAccommodation?.accommodationId,
      accommodationName: currentAccommodation?.name,
      subscriptionId: item.zuoraSubscriptionId,
      subscriptionNumber: item.zuoraSubscriptionName,
      nextPaymentDate: item?.nextPaymentDate,
      subscriptionStartDate: item?.subscriptionStartDate,
      status: getStatus(item),
      type: item.type,
      term: item.term,
      subscriptionType: item.term,
      endDate: item?.endDate ?? null,
      createdAt: item?.createdAt ?? null,
      partnerId: item?.partnerId ?? null,
    }
  })
})

const accommodationHasRateConnect = currentSubscription => {
  const allAccommodationSubscriptions = studioPlusCampaigns.value.filter(
    item => item.itemId === currentSubscription.itemId
  )
  const hasRC = allAccommodationSubscriptions.find(sub => sub.type === 'RATE_CONNECT')
  if (hasRC) return true
  else return false
}

const subscriptionsByStatus = computed(() => {
  const subscriptionsWithData = subscriptions.value
  const subscriptionsWithStatus = {
    active: [],
    expired: [],
  }

  subscriptionsWithData.forEach(subscription => {
    let statusValue
    if (subscription.status === 'cancelled' || subscription.status === 'canceled') {
      statusValue = 'active'
    } else {
      statusValue = subscription.status
    }

    subscriptionsWithStatus[statusValue].push({
      ...subscription,
    })
  })

  return subscriptionsWithStatus
})

onMounted(() => {
  if (route.query?.reactivate === 'true') {
    displayNotification({
      message: 'business_studio_plus_reacativation_success',
      isTranslationKey: true,
      type: 'success',
    })
  }
})
</script>
