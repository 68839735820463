import { computed } from 'vue'
import { getDataFormattedForGraph } from '@/utils/Utility'
import { useQuery } from '@tanstack/vue-query'
import DashboardService from '@/services/DashboardService'

const getPartnersNames = async ({ partnerIds }) => {
  const response = await DashboardService.fetchPartnersName(partnerIds)
  return response?.data?.data?.getAdvertiserDetails?.advertiserDetails
}

export function usePartnerNames(partnerIds) {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ['partnerNames', partnerIds],
    queryFn: ({ queryKey }) =>
      getPartnersNames({
        partnerIds: queryKey[1],
      }),
  })

  const partners = computed(() => data?.value ?? [])

  return {
    partners,
    isError,
    isLoading,
    partnerMapIsFetching: isFetching,
  }
}

export function useConversionFunnelData(accommodationId) {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ['conversionFunnel', accommodationId],
    queryFn: ({ queryKey }) => DashboardService.fetchConversionFunnel({ accommodationId: queryKey[1] }),
  })

  const conversionFunnel = computed(() => data?.value?.data)

  const viewsDataFormattedForChart = computed(() => {
    if (!conversionFunnel.value?.views?.graph) return
    return getDataFormattedForGraph(conversionFunnel.value.views.graph)
  })
  const views = computed(() => conversionFunnel.value?.views?.total)
  const viewsVariation = computed(() => conversionFunnel.value?.views?.variation)
  const viewsPercentage = computed(() => conversionFunnel.value?.views?.percentage)
  const viewsPercentageOf = computed(() => conversionFunnel.value?.views?.percentage_of)
  const visitsDataFormattedForChart = computed(() => {
    if (!conversionFunnel.value?.visits?.graph) return
    return getDataFormattedForGraph(conversionFunnel.value.visits.graph)
  })
  const visits = computed(() => conversionFunnel.value?.visits?.total)
  const visitsVariation = computed(() => conversionFunnel.value?.visits?.variation)
  const visitsPercentage = computed(() => conversionFunnel.value?.visits?.percentage)
  const visitsPercentageOf = computed(() => conversionFunnel.value?.visits?.percentage_of)
  const partnerDistribution = computed(() => conversionFunnel.value?.partner_distribution)

  return {
    conversionFunnel,
    viewsDataFormattedForChart,
    views,
    viewsVariation,
    viewsPercentage,
    viewsPercentageOf,
    visitsDataFormattedForChart,
    visits,
    visitsVariation,
    visitsPercentage,
    visitsPercentageOf,
    partnerDistribution,
    isError,
    isLoading,
    isFetching,
  }
}
