<template>
  <div class="tw-my-6 tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mx-auto">
    <LaunchpadLoader v-if="initialDataLoading" class="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-1" />
    <router-view v-else />
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useStudioPlusCampaign, useStudioPlusPrice } from '@/components/payment/queries'
import useRateConnect from '@/components/rateConnect/queries'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import { selectedAccommodationId, useAccommodationsByOrgId } from '@/layouts/queries'
import { ELIGIBLE_COUNTRIES, ELIGIBLE_PARTNERS } from '@/constants/studioPlusEligibility.js'
// Temporary Studio Plus Flag
import { isPlusOnboardingVisible } from '@/constants/studioPlusOnboarding.js'

const store = useStore()
const router = useRouter()

const reactivationAccommodationId = computed(() => store.state.studioPlus.reactivationAccommodationId)
const reactivationSubscriptionType = computed(() => store.state.studioPlus.reactivationSubscriptionType)
const { studioPlusPrice } = useStudioPlusPrice(selectedAccommodationId)
const selectedOrganisationId = computed(() => store.state.session?.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)

const userRole = computed(() => store.state.session?.userRole)
const isDeepLink =
  window.localStorage.getItem('fromDeepLink') ||
  (router.currentRoute.value.query.itemID && router.currentRoute.value.query.orgID)
const { hotelierData, isStartUpLoading, isStartUpFetching } = useRateConnect(selectedAccommodationId)
const { isPlus, isLoading, isFetching, isPro, isExpiredSubscription } = useStudioPlusCampaign(selectedAccommodationId)

const initialDataLoading = computed(() => isLoading.value || isStartUpLoading.value)
const isRequiredDataFetching = computed(() => isFetching.value || isStartUpFetching.value)
const currency = computed(() => store.state.currency)

const isEligibleForStudioPlus = computed(() => {
  if (initialDataLoading.value) return false
  if (userRole.value !== 'admin') return false
  if (isPro.value && !isExpiredSubscription.value) return false
  if (currency.value !== 'EUR') return false
  if (!studioPlusPrice.value) return false
  if (!ELIGIBLE_COUNTRIES.includes(currentAccommodation.value?.countryId)) return false
  if (!ELIGIBLE_PARTNERS.includes(hotelierData.value?.directRatesPartner)) return false
  if (isPlus.value) return false
  if (hotelierData.value?.hasRateConnect) return false
  return true
})

watch(isRequiredDataFetching, newVal => {
  if (!isPlusOnboardingVisible) {
    router.replace('/')
  } else {
    if (isDeepLink && userRole.value !== 'admin') {
      window.localStorage.removeItem('fromDeepLink')
      router.replace({ name: 'not-admin-error' })
    } else if (newVal === false && isEligibleForStudioPlus.value === false) {
      if (reactivationAccommodationId.value === null && reactivationSubscriptionType.value === null) {
        router.replace('/')
      }
    }
  }
})

watch(selectedAccommodationId, () => {
  router.replace('/')
})
</script>
