<template>
  <div class="md:tw-flex md:tw-items-center" v-if="!hasNoAccommodations">
    <button
      class="tw-flex tw-justify-between tw-items-center tw-text-sm tw-py-1.5 tw-my-1 tw-w-full tw-px-4 md:tw-hidden"
      @click="toggleMobileAppNav"
      @keydown="toggleMobileAppNav"
    >
      <div class="tw-flex tw-items-center">
        <span class="tw-text-base tw-font-bold tw-text-blue-700">{{ $t(activeLink?.name) }}</span>
        <span
          v-if="activeLink.badge"
          class="tw-px-2 tw-text-[10px] tw-ml-2 tw-text-white tw-font-bold tw-bg-red-700 tw-rounded-tl-2xl tw-rounded-br-2xl"
          >{{ $t(activeLink.badge) }}</span
        >
      </div>
      <div
        class="tw-h-8 tw-rounded-full tw-w-8 tw-relative tw-bg-gray-200 hover:tw-bg-gray-300 tw-right-0 tw-transition-all tw-ease-in-out tw-duration-200"
      >
        <div
          :class="{
            'down-triangle': !isMobileAppNavOpen,
            'up-triangle': isMobileAppNavOpen,
          }"
          class="tw-z-0 tw-h-2 tw-w-2 tw-absolute tw-right-0"
        ></div>
      </div>
    </button>
    <hr v-if="isMobileAppNavOpen" class="md:tw-hidden" />
    <div v-if="isMenuRequiredDataFetching" class="tw-h-[56px] tw-flex tw-items-center tw-rounded-lg">
      <UiNavigationLink
        :ref="$t('navigation.home')"
        to="/"
        tag="router-link"
        isUnderlineHover
        class="tw-text-gray-800 hover:tw-text-blue-700 tw-relative tw-text-base md:tw-text-sm ui-navigation-link tw-px-4 md:tw-px-0 tw-h-full"
        @onClick="closeMobileNav()"
      >
        {{ $t('navigation.home') }}
      </UiNavigationLink>
    </div>
    <ul
      v-else-if="navLinks.length > 0"
      class="tw-w-full tw-justify-between tw-flex md:tw-items-center tw-flex-col md:tw-flex-row"
    >
      <ul
        :class="{ 'tw-hidden': !isMobileAppNavOpen }"
        class="md:tw-flex tw-items-center tw-justify-center md:tw-h-14 tw-relative tw-py-1 md:tw-py-0"
      >
        <li
          v-for="(app, index) in navLinks"
          :key="index"
          data-cy="app-list"
          class="ui-navigation-list-item md:tw-ml-8 xl:tw-ml-10 tw-h-full"
        >
          <UiNavigationLink
            :ref="$t(app?.name)"
            :to="app.to"
            :is-icon="app.icon !== null"
            isUnderlineHover
            tag="router-link"
            class="tw-text-gray-800 hover:tw-text-blue-700 tw-relative tw-text-base md:tw-text-sm ui-navigation-link tw-px-4 md:tw-px-0 tw-h-full"
            @onClick="closeMobileNav()"
            :dataTestId="`app-${app.name}`"
          >
            {{ $t(app?.name) }}
            <span
              v-if="app.badge"
              class="md:tw-absolute tw-left-0 md:tw-ml-0 tw-ml-2 tw-top-0 tw-px-2 md:tw-py-0 tw-py-1 md:tw-py-auto tw-text-[10px] tw-text-white tw-font-bold tw-bg-red-800 tw-rounded-tl-2xl tw-rounded-br-2xl"
              >{{ $t(app.badge) }}</span
            >
          </UiNavigationLink>
        </li>
      </ul>
      <hr v-if="isMobileAppNavOpen" class="md:tw-hidden" />
      <ul
        :class="{ 'tw-hidden': !isMobileAppNavOpen }"
        class="md:tw-flex tw-items-center tw-justify-center md:tw-h-14 tw-relative tw-my-1 md:tw-m-0"
      >
        <li v-if="selectedAccommodationId" class="tw-flex tw-justify-between trivago-url">
          <UiNavigationLink
            :ref="$t('tbs_property_link_trivago')"
            :href="`https://www.trivago.com/${platformsLanguages[language]}/srl?search=100-${selectedAccommodationId}`"
            :is-icon="false"
            tag="a"
            target="_blank"
            class="tw-text-gray-800 tw-relative tw-text-base md:tw-text-sm ui-navigation-link tw-px-4 md:tw-px-0 tw-h-full tw-w-full md:tw-w-auto"
            @onClick="closeMobileNav()"
            dataTestId="trivago-property-link"
          >
            <span>{{ $t('tbs_property_link_trivago') }}</span>
            <BaseIcon
              height="24"
              width="24"
              icon-name="new-tab"
              class="tw-fill-none tw-ml-2 tw-stroke-gray-800 tw-my-2.5 tw-mx-4 md:tw-mr-0"
            >
              <NewTab />
            </BaseIcon>
          </UiNavigationLink>
        </li>
      </ul>
    </ul>
  </div>
</template>
<script setup>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from 'vue'

import UiNavigationLink from '@/components/dashboard/ui/UiNavigationLink.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import NewTab from '@/components/icons/NewTab.vue'
import { platformsLanguages } from '@/constants/platformLanguages.js'
import { useStartUp } from '@/components/rateConnect/queries'
import { useStudioPlusCampaign, useStudioPlusPrice } from '@/components/payment/queries'
import { selectedAccommodationId, useAccommodationsByOrgId, useOrganisationAssignments } from '@/layouts/queries'

import { ELIGIBLE_COUNTRIES, ELIGIBLE_PARTNERS } from '@/constants/studioPlusEligibility.js'
// Temporary Studio Plus Flag
import { isPlusOnboardingVisible } from '@/constants/studioPlusOnboarding.js'

const route = useRoute()
const store = useStore()
const props = defineProps({
  isMobileAppNavOpen: Boolean,
  isMobile: Boolean,
})

const emit = defineEmits(['toggleMobileAppNav'])
const toggleMobileAppNav = () => emit('toggleMobileAppNav')

const language = computed(() => store.state.locale.language)
const userRole = computed(() => store.state.session?.userRole)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const {
  isFetching: isStudioPlusCampaignFetching,
  isExpiredSubscription,
  isPro,
  isPlus,
} = useStudioPlusCampaign(selectedAccommodationId)

const currentRouteName = computed(() => route.name)
const { assignmentsData } = useOrganisationAssignments(selectedOrganisationId)
const hasNoAccommodations = computed(
  () => !assignmentsData.value || !Array.isArray(assignmentsData.value) || assignmentsData.value?.length === 0
)

const navLinks = computed(() => {
  let homeLink = {
    to: '/',
    isStudio: true,
    name: 'navigation.home',
    isActive: currentRouteName.value.includes('home'),
  }
  let rcLink = {
    to: '/rate-connect',
    isStudio: true,
    name: 'app.rateConnect.name',
    isActive: currentRouteName.value.includes('rate-connect'),
  }
  let propertyDetailsLink = {
    to: '/property-details',
    isStudio: true,
    name: 'app.propertyDetails.name',
    isActive: currentRouteName.value.includes('property-details'),
  }
  let allLinks = [homeLink, rcLink, propertyDetailsLink]

  // If no accommodations exist (first onboarding) or are not selected (should not happen), don't show the menu, otherwise apps will crash.
  if (hasNoAccommodations.value || !selectedAccommodationId.value) return allLinks

  if (isAccommodationEligibleForStudioPlus.value && isPlusOnboardingVisible) {
    allLinks = [homeLink, rcLink, propertyDetailsLink]
    if (userRole.value === 'admin') {
      allLinks = [
        ...allLinks,
        {
          to: '/studio-plus/compare',
          badge: 'business_studio_plus_free_trial_flag',
          isStudio: true,
          name: 'Business Studio+',
          isActive: currentRouteName.value.includes('studio-plus'),
        },
      ]
    }
  } else if (isPlus.value && !isExpiredSubscription.value && !props.isMobile) {
    allLinks = [
      homeLink,
      {
        to: '/rate-insights',
        isStudio: true,
        name: 'app.rateInsights.name',
        isActive: currentRouteName.value.includes('rate-insights'),
      },
      {
        to: '/visitors-profile',
        badge: 'business_studio_plus_new_flag',
        isStudio: true,
        name: 'app.visitorsProfile.name',
        isActive: currentRouteName.value.includes('visitors-profile'),
      },
      propertyDetailsLink,
    ]
  } else if (isPro.value && !isExpiredSubscription.value && !props.isMobile) {
    allLinks = [
      homeLink,
      rcLink,
      {
        to: '/rate-insights',
        isStudio: true,
        name: 'app.rateInsights.name',
        isActive: currentRouteName.value.includes('rate-insights'),
      },
      {
        to: '/visitors-profile',
        badge: 'business_studio_plus_new_flag',
        isStudio: true,
        name: 'app.visitorsProfile.name',
        isActive: currentRouteName.value.includes('visitors-profile'),
      },
      propertyDetailsLink,
    ]
  } else if (isPlus.value && !isExpiredSubscription.value && props.isMobile) {
    allLinks = [homeLink, propertyDetailsLink]
  } else if (isPro.value && !isExpiredSubscription.value && props.isMobile) {
    allLinks = [homeLink, rcLink, propertyDetailsLink]
  } else {
    allLinks = [...allLinks]
  }

  return allLinks
})

const activeLink = computed(() => {
  if (navLinks.value?.filter(item => item.isActive).length > 0) {
    return navLinks.value.filter(item => item.isActive)[0]
  } else if (navLinks.value.filter(item => item.app_id === route.params.id).length > 0) {
    return navLinks.value.filter(item => item.app_id === route.params.id)[0]
  } else {
    return navLinks.value[0]
  }
})

const closeMobileNav = () => {
  toggleMobileAppNav()
}

const currency = computed(() => store.state.currency)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === selectedAccommodationId.value)
)

const { hotelierData } = useStartUp(selectedAccommodationId)
const { studioPlusPrice, isFetching: isStudioPlusPriceFetching } = useStudioPlusPrice(selectedAccommodationId)

const isMenuRequiredDataFetching = computed(() => isStudioPlusCampaignFetching.value || isStudioPlusPriceFetching.value)

const isAccommodationEligibleForStudioPlus = computed(() => {
  if (isPlus.value) return false
  if (!ELIGIBLE_PARTNERS.includes(hotelierData.value?.directRatesPartner)) return false
  if (hotelierData.value?.hasRateConnect) return false
  if (isPro.value && !isExpiredSubscription.value) return false
  if (!studioPlusPrice.value) return false
  if (currency.value !== 'EUR') return false
  if (!ELIGIBLE_COUNTRIES.includes(currentAccommodation.value?.countryId)) return false
  return true
})
</script>
<style scoped>
a.router-link-active {
  @apply tw-font-bold;
  @apply tw-text-blue-700;
}
.ui-navigation-list-item:first-child {
  @apply tw-ml-0;
}

@media (min-width: theme('screens.md')) {
  .ui-navigation-list-item:first-child {
    @apply tw-inline;
  }
}

.ui-navigation-list-item:last-child {
  @apply tw-mr-4;
}

.ui-navigation-link .app-icon {
  filter: grayscale(1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ui-navigation-link:hover .app-icon,
.router-link-active .app-icon {
  filter: grayscale(0) !important;
}
.down-triangle {
  @apply tw-border-l-4;
  @apply tw-border-r-4;
  @apply tw-border-t-4;

  top: 15px;
  left: 12px;

  border-left-color: theme('colors.transparent');
  border-right-color: theme('colors.transparent');
  border-top-color: theme('colors.black');
}

.trivago-url:hover svg,
.trivago-url a:hover > svg,
.trivago-url:hover a,
.trivago-url a svg:hover {
  @apply tw-stroke-blue-700 tw-text-blue-700 tw-underline;
}

.up-triangle {
  @apply tw-border-l-4;
  @apply tw-border-r-4;
  @apply tw-border-t-4;

  top: 10px;
  left: 12px;

  border-left-color: theme('colors.transparent');
  border-right-color: theme('colors.transparent');
  border-top-color: theme('colors.black');
  transform: rotate(180deg);
}
</style>
