<template>
  <div>
    <div class="md:tw-m-auto" :class="currentStep === 3 ? 'tw-w-auto' : 'tw-w-auto md:tw-w-[827px] '">
      <Stepper :current-step="currentStep" @onNext="onNextAction" @onBack="onBackAction" />
      <div class="tw-my-8">
        <SetupSelectCampaignType
          v-if="currentStep === 1"
          :eligibleTypeOfCPACampaign="eligibleTypeOfCPACampaign"
          :selectedCampaignType="selectedCampaignType"
          @onSelectedCampaignTypeChange="onSelectedCampaignTypeChange"
        />
        <CPACampaignSetup
          v-if="currentStep === 2 && isSelectedCampaignTypeCPA"
          :eligibleCommissionLevels="eligibleCommissionLevels"
          :selectedCampaignType="selectedCampaignType"
          :selectedCommissionLevel="selectedCommissionLevel"
          @onSelectedCommissionChange="onSelectedCommissionChange"
        />
        <PaymentPage
          v-if="currentStep === 3"
          :selectedCommissionLevel="selectedCommissionLevel"
          :rcCampaignCreationInProgress="rcCampaignCreationInProgress"
          @onCheckoutSuccess="onCheckoutSuccess"
          @setRCCampaignCreationInProgress="setRCCampaignCreationInProgress"
        />
      </div>
      <SetupFooter
        :currentStep="currentStep"
        :next-disabled="isNextDisabled"
        :is-loading="rcCampaignCreationInProgress"
        @onNext="onNextAction"
        @onBack="onBackAction"
      />
    </div>
    <FullScreenLoader v-show="isStartUpFetching || rcCampaignCreationInProgress" />
  </div>
</template>
<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import Stepper from '@/components/rateConnect/setup/SetupStepper.vue'
import SetupFooter from '@/components/rateConnect/setup/SetupFooter.vue'
import CPACampaignSetup from '@/components/rateConnect/setup/CPACampaignSetup.vue'
import PaymentPage from '@/components/rateConnect/setup/PaymentPage.vue'
import SetupSelectCampaignType from '@/components/rateConnect/setup/SetupSelectCampaignType.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useSetup } from './useSetup'
import {
  useBudgetRecommendation,
  useCampaignsMutation,
  usePartners,
  useStartUp,
} from '@/components/rateConnect/queries'
import appAnalytics from '@/utils/tracking'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const router = useRouter()
const route = useRoute()
const {
  currentStep,
  selectedCampaignType,
  isSelectedCampaignTypeCPA,
  selectedCommissionLevel,
  selectedBudget,
  selectedPartner,
  resetSetup,
} = useSetup()
const currency = computed(() => store.state.currency)
const language = computed(() => store.state.locale?.language)
const {
  isFetching: isStartUpFetching,
  isItemNetCPAEligible,
  eligibleCommissionLevels,
} = useStartUp(selectedAccommodationId)
const { partnersData } = usePartners(selectedAccommodationId)
const recommendedPartner = computed(() => partnersData.value[0])
const { isBudgetValid } = useBudgetRecommendation(selectedAccommodationId, currency, recommendedPartner)
const { submitCampaign } = useCampaignsMutation()

const rcCampaignCreationInProgress = ref(false)

const setRCCampaignCreationInProgress = loading => {
  rcCampaignCreationInProgress.value = loading
}

const isNextDisabled = computed(() => {
  if (currentStep.value === 1) {
    return selectedCampaignType.value === null
  } else if (currentStep.value === 2) {
    return isSelectedCampaignTypeCPA.value
      ? selectedCommissionLevel.value === null
      : !isBudgetValid(selectedBudget.value)
  }
  return false
})

const eligibleTypeOfCPACampaign = computed(() => (isItemNetCPAEligible.value === true ? 'netCpa' : null))

const onCheckoutSuccess = ({ zuoraSubscriptionNumber }) => {
  appAnalytics.track(appAnalytics.events.RC_ONBOARDING_PAYMENT_SUCCESS, {
    item_id: selectedAccommodationId.value,
    partner_id: selectedPartner.value?.value,
    campaign_type: selectedCampaignType.value,
    commission_level: selectedCommissionLevel.value,
    budget: selectedBudget.value * 100,
  })
  rcCampaignCreationInProgress.value = true
  const requestOptions = {
    headers: {
      'X-Trv-Localization': language.value,
    },
    body: {
      overall: {
        campaignType: selectedCampaignType.value,
        currencyCode: currency.value,
        partnerId: selectedPartner.value?.value,
        useAutomatedMarkets: true,
        zuoraSubscriptionNumber: zuoraSubscriptionNumber,
        ...(isSelectedCampaignTypeCPA.value === true && { cpa: selectedCommissionLevel.value }),
        ...(selectedCampaignType.value === 'cpc' && { budget: { limit: selectedBudget.value * 100 } }),
      },
      ...(selectedCampaignType.value === 'cpc' && { campaigns: [] }),
    },
  }
  submitCampaign(
    { accommodationId: selectedAccommodationId.value, options: requestOptions },
    {
      onSettled: () => {
        router.push({ name: 'rate-connect-running-performance' })
        resetSetup()
      },
    }
  )
  resetSetup()
}

const onNextAction = () => {
  if (currentStep.value === 1) {
    if (isSelectedCampaignTypeCPA.value) selectedCommissionLevel.value = 15
    currentStep.value = 2
  } else if (currentStep.value === 2) {
    currentStep.value = 3
  }
}
const onBackAction = () => {
  if (currentStep.value === 1) {
    router.push('/rate-connect')
  } else if (currentStep.value > 1) {
    currentStep.value -= 1
  }
}

const onSelectedCampaignTypeChange = type => (selectedCampaignType.value = type)
const onSelectedCommissionChange = level => (selectedCommissionLevel.value = level)

onMounted(() => {
  resetSetup()
  selectedCampaignType.value = isItemNetCPAEligible.value === true ? 'netCpa' : 'cpc'
  if (route.query?.source) {
    selectedCommissionLevel.value = 15
    currentStep.value = 2
  }
  const trafficSource = window.sessionStorage?.getItem?.('traffic_source')
  appAnalytics.track(appAnalytics.events.RC_ONBOARDING_STARTED, {
    item_id: selectedAccommodationId.value,
    partner_id: selectedPartner.value?.value,
    source: route.query?.source ? route.query.source : 'RCCreateCampaign',
    traffic_source: trafficSource ?? '',
  })
})
</script>
<style scoped>
.app-container-loader {
  @apply tw-absolute tw-inset-0 tw-z-50;
  background: theme('colors.white');
  opacity: 0.8;
}
.app-container {
  @apply tw-absolute tw-inset-0 tw-block tw-w-full tw-h-screen;
}
.app-container-checkout-page-active {
  top: 0;
  @apply tw-h-checkout-min-height tw-z-50;
}
</style>
