<template>
  <section>
    <div class="tw-bg-decorative-blue">
      <div class="tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-mx-auto">
        <p class="tw-text-white tw-text-xl md:tw-text-3xl tw-font-bold tw-py-[26px]">
          {{ $t('rc_mp_steps_banner') }}
        </p>
      </div>
    </div>
    <div class="tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-mx-auto">
      <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-mt-10 tw-gap-7 tw-items-stretch">
        <div v-for="(step, index) in steps" :key="index" class="tw-w-full landing:tw-w-1/3">
          <div class="tw-mb-8">
            <p class="tw-text-2xl tw-text-blue-700 tw-font-bold">{{ $t(step.title) }}</p>
            <p class="tw-text-2xl tw-font-bold">{{ $t(step.subtext) }}</p>
          </div>
          <div
            v-if="index === 0"
            class="tw-hidden md:tw-flex tw-bg-gray-100 tw-rounded-lg landing:tw-mb-16 tw-mb-4 tw-gap-0 md:tw-gap-6 lg:tw-gap-0 tw-px-5 tw-py-7 landing:tw-min-h-[453px] tw-flex-col md:tw-flex-row lg:tw-flex-col tw-justify-between md:tw-items-end lg:tw-items-start"
          >
            <p class="md:tw-order-1 lg:tw-order-0" v-html="$t('rc_mp_step_1_point_1')" />
            <img
              :src="GuideOne"
              :alt="steps[index].title"
              class="md:tw-order-0 lg:tw-order-1 tw-mt-5 md:tw-mt-0 lg:tw-mt-5 tw-h-[80px]"
            />
          </div>
          <div
            v-if="index === 0"
            class="tw-flex md:tw-hidden tw-bg-gray-100 tw-rounded-lg landing:tw-mb-16 tw-mb-4 tw-gap-0 md:tw-gap-6 lg:tw-gap-0 tw-px-5 tw-py-7 landing:tw-min-h-[453px] tw-flex-col md:tw-flex-row lg:tw-flex-col tw-justify-between tw-items-start"
          >
            <p v-html="$t('rc_mp_step_1_point_1')" />
            <img :src="GuideOne" :alt="steps[index].title" class="tw-mt-5 tw-h-[80px]" />
          </div>

          <div
            v-if="index === 1"
            class="tw-bg-gray-100 tw-rounded-lg landing:tw-mb-16 tw-mb-4 tw-px-5 tw-py-7 landing:tw-min-h-[453px] tw-flex tw-flex-col tw-justify-between tw-items-start"
          >
            <p v-html="$t('rc_mp_step_2_point_1')" />

            <ul class="tw-mt-5">
              <li v-for="item in billingList" :key="item" class="tw-flex tw-items-start tw-mb-4 last:tw-mb-0">
                <BaseIcon class="tw-fill-none tw-mr-4 tw-mt-1">
                  <TickCircle />
                </BaseIcon>
                {{ $t(item) }}
              </li>
            </ul>
          </div>
          <div
            v-if="index === 2"
            class="tw-bg-gray-100 tw-rounded-lg tw-mb-16 tw-px-5 tw-py-7 landing:tw-min-h-[453px] tw-flex tw-flex-col tw-justify-between tw-items-start"
          >
            <ul class="tw-flex tw-flex-col tw-w-full">
              <li v-for="item in settingsList" :key="item" class="item tw-flex tw-flex-row tw-items-start tw-bg-white tw-mb-3.5 tw-rounded-lg tw-pl-4 tw-p-2.5">
                {{ $t(item) }}
              </li>
            </ul>
            <img :src="GuideThree" :alt="steps[index].title" class="tw-mt-5 tw-h-[50px] tw-ml-auto" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import TickCircle from '@/components/icons/TickCircle.vue'
import BaseIcon from '@/components/BaseIcon.vue'

const GuideOne = new URL('/src/assets/img/rate-connect-landing/guide-1.svg', import.meta.url)
const GuideThree = new URL('/src/assets/img/studio-plus-landing/guide-3.svg', import.meta.url)

const steps = [
  { title: 'rc_mp_step_1_header', subtext: 'rc_mp_step_1_subtext' },
  { title: 'rc_mp_step_2_header', subtext: 'rc_mp_step_2_subtext' },
  { title: 'rc_mp_step_3_header', subtext: 'rc_mp_step_3_subtext' },
]

const settingsList = ['rc_mp_step_3_point_1', 'rc_mp_step_3_point_2', 'rc_mp_step_3_point_3', 'rc_mp_step_3_point_4']

const billingList = ['rc_mp_step_2_point_2', 'rc_mp_step_2_point_3', 'rc_mp_step_2_point_4']
</script>
<style scoped>
.item::before {
  content: '';
  height: 5px;
  width: 5px;
  min-width:5px;
  @apply  tw-my-2.5 tw-mr-2 tw-bg-gray-700 tw-rounded-full;
}
</style>