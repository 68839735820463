<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15.5 18.25H4.25C2.87125 18.25 1.75 17.1287 1.75 15.75C1.75 13.1063 3.81875 10.9575 6.42 10.7838C7.375 11.5338 8.565 12 9.875 12C11.185 12 12.375 11.5338 13.33 10.7838C15.9312 10.9575 18 13.1063 18 15.75C18 17.1287 16.8787 18.25 15.5 18.25ZM9.875 2C12.2875 2 14.25 3.9625 14.25 6.375C14.25 7.6 13.7413 8.705 12.9275 9.5C12.1388 10.2712 11.0625 10.75 9.875 10.75C8.6875 10.75 7.61125 10.2712 6.8225 9.5C6.00875 8.705 5.5 7.6 5.5 6.375C5.5 3.9625 7.4625 2 9.875 2ZM14.4125 9.675C15.0887 8.745 15.5 7.6125 15.5 6.375C15.5 3.26875 12.9812 0.75 9.875 0.75C6.76875 0.75 4.25 3.26875 4.25 6.375C4.25 7.6125 4.66125 8.745 5.3375 9.675C2.56875 10.3162 0.5 12.785 0.5 15.75C0.5 17.8212 2.17875 19.5 4.25 19.5H15.5C17.5712 19.5 19.25 17.8212 19.25 15.75C19.25 12.785 17.1812 10.3162 14.4125 9.675Z"
    fill="#171717"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M24.875 4.5H21.75V1.375C21.75 1.03 21.47 0.75 21.125 0.75C20.78 0.75 20.5 1.03 20.5 1.375V4.5H17.375C17.03 4.5 16.75 4.78 16.75 5.125C16.75 5.47 17.03 5.75 17.375 5.75H20.5V8.875C20.5 9.22 20.78 9.5 21.125 9.5C21.47 9.5 21.75 9.22 21.75 8.875V5.75H24.875C25.22 5.75 25.5 5.47 25.5 5.125C25.5 4.78 25.22 4.5 24.875 4.5Z"
    fill="#171717"
  />
</template>
