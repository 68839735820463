<template>
  <div>
    <Carousel :slides="slides">
      <template v-slot="{ selectedSlide, selectedSlideName }">
        <CarouselItem v-for="(slide, i) in slides" :key="i" :slide-position="i + 1" :selected-slide="selectedSlide">
          <div class="md:tw-flex md:tw-justify-between tw-h-full">
            <div class="tw-flex tw-flex-col tw-grow">
              <div class="md:tw-pr-3">
                <h1 class="md:tw-leading-snug tw-font-bold tw-pb-3 tw-leading-10">
                  {{ $t(slide.headKey) }}
                </h1>
                <p class="tw-text-gray-800 tw-text-base lg:tw-text-base tw-max-w-full tw-mb-6">
                  {{ $t(slide.textKey) }}
                </p>
              </div>
              <div>
                <MainButton
                  v-if="['rc_promote', 'rc_resume'].includes(selectedSlideName)"
                  @click="slide.onCtaClick()"
                  >{{ $t(slide.ctaKey) }}</MainButton
                >
                <GhostButton size="big" v-else @click="slide.onCtaClick()">{{ $t(slide.ctaKey) }}</GhostButton>
              </div>
            </div>
          </div>
        </CarouselItem>
      </template>
      <template v-slot:modal>
        <CustomModal
          class="carousel-modal"
          :is-modal-open="isModalOpen"
          @toggleModal="toggleModal"
          @onClickAway="toggleModal"
          :with-header="false"
          :overlay="'light'"
          :custom-classes="'tw-max-w-screen-xl tw-rounded-md'"
        >
          <div class="md:tw-flex md:tw-px-20 tw-py-5 md:tw-py-0">
            <div class="md:tw-w-1/3">
              <div class="md:tw-mr-16 md:tw-pt-12">
                <ul class="tw-mb-4 md:tw-mb-0 tw-w-full tw-hidden md:tw-block">
                  <li v-for="(slide, i) in slides" :key="i">
                    <button
                      :class="
                        selectedModalSidebarOption === i + 1
                          ? 'tw-bg-blue-700 tw-text-white'
                          : 'tw-bg-white tw-text-blue-700'
                      "
                      class="tw-px-4 tw-w-full tw-py-2 tw-text-left tw-outline-none focus:tw-outline-none tw-rounded-md"
                      @click="updateModalSidebarOption(i + 1)"
                      @keydown.enter="updateModalSidebarOption(i + 1)"
                    >
                      {{ $t(slide.overlaySidebarKey) }}
                    </button>
                  </li>
                </ul>
              </div>
              <Dropdown
                class="tw-block md:tw-hidden tw-mb-8"
                :selected="$t(`tbs_overlay_sidebar_${selectedModalSidebarOption}`)"
              >
                <template v-slot="{ toggle }">
                  <div>
                    <ul class="tw-pt-2 tw-border tw-border-t-0 tw-border-blue-500 tw-rounded-md tw--mt-2">
                      <li
                        v-for="(slide, i) in slides"
                        :key="i"
                        :class="{ 'tw-hidden': selectedModalSidebarOption === i + 1 }"
                      >
                        <button
                          class="tw-text-blue-700 tw-text-sm tw-w-full tw-p-2 tw-text-left"
                          @click="handleDropdownItemClick(i + 1, toggle)"
                          @keydown.enter="handleDropdownItemClick(i + 1, toggle)"
                        >
                          {{ $t(slide.overlaySidebarKey) }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </template>
              </Dropdown>
            </div>
            <div class="md:tw-w-2/3">
              <div v-for="(slide, i) in slides" :key="i">
                <div v-if="selectedModalSidebarOption === i + 1">
                  <h2 class="tw-text-4xl tw-mb-4">{{ $t(slide.overlayHeadKey) }}</h2>
                  <div class="carousel-modal-body tw-mb-4" v-html="$t(slide.overlayTextKey)"></div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </template>
    </Carousel>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import Carousel from '@/components/dashboard/carousel/Carousel.vue'
import CarouselItem from '@/components/dashboard/carousel/CarouselItem.vue'
import CustomModal from '@/components/base/CustomModal.vue'
import Dropdown from '@/components/dashboard/dropdown/Dropdown.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'

const selectedModalSidebarOption = ref(null)
const isModalOpen = ref(false)

const toggleModal = () => {
  isModalOpen.value = !isModalOpen.value
}

const openReadMore = selectedSlideName => {
  const selectedSlide = slides.value.findIndex(item => item.name === selectedSlideName)
  selectedModalSidebarOption.value = selectedSlide + 1
  toggleModal()
}

const slides = ref([
  {
    name: 'my_property',
    headKey: 'tbs_home_carousel_1_header',
    textKey: 'tbs_home_carousel_1_text',
    ctaKey: 'tbs_home_carousel_1_cta',
    onCtaClick: function () {
      openReadMore(this.name)
    },
    overlaySidebarKey: 'tbs_overlay_sidebar_1',
    overlayHeadKey: 'tbs_home_overlay_1_header',
    overlayTextKey: 'tbs_home_overlay_1_text',
  },
  {
    name: 'my_business_studio',
    headKey: 'tbs_home_carousel_2_header',
    textKey: 'tbs_home_carousel_2_text',
    ctaKey: 'tbs_home_carousel_2_cta',
    onCtaClick: function () {
      openReadMore(this.name)
    },
    overlaySidebarKey: 'tbs_overlay_sidebar_2',
    overlayHeadKey: 'tbs_home_overlay_2_header',
    overlayTextKey: 'tbs_home_overlay_2_text',
  },
  {
    name: 'all_about_trivago',
    headKey: 'tbs_home_carousel_3_header',
    textKey: 'tbs_home_carousel_3_text',
    ctaKey: 'tbs_home_carousel_3_cta',
    onCtaClick: function () {
      openReadMore(this.name)
    },
    overlaySidebarKey: 'tbs_overlay_sidebar_3',
    overlayHeadKey: 'tbs_home_overlay_3_header',
    overlayTextKey: 'tbs_home_overlay_3_text',
  },
])

const updateModalSidebarOption = value => {
  selectedModalSidebarOption.value = value
}

const handleDropdownItemClick = (value, cb) => {
  updateModalSidebarOption(value)
  if (cb) {
    cb()
  }
}
</script>
<style scoped>
h1 {
  font-size: 2.5rem;
}
.carousel-modal-body :deep(p) {
  @apply tw-mb-6;
  color: theme('colors.gray.800');
}
.carousel-modal-body :deep(p:first-child) {
  color: theme('colors.gray.500');
}

.carousel-modal-body :deep(p:last-child) {
  margin-bottom: 0;
}
</style>
