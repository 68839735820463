<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M17.2548 1.94863C18.476 3.08988 19.2498 4.70113 19.2498 6.49988C19.2498 8.29863 18.476 9.90988 17.2548 11.0511C20.801 10.2799 23.4798 8.57863 24.2498 6.49988C23.4798 4.42238 20.801 2.71988 17.2548 1.94863ZM1.74976 6.49988C2.51976 8.57863 5.19851 10.2799 8.74476 11.0511C7.52476 9.90988 6.74976 8.29863 6.74976 6.49988C6.74976 4.70113 7.52476 3.08988 8.74476 1.94863C5.19851 2.71988 2.51976 4.42238 1.74976 6.49988ZM17.9998 6.49988C17.9998 3.73863 15.761 1.49988 12.9998 1.49988C10.2385 1.49988 7.99976 3.73863 7.99976 6.49988C7.99976 9.26113 10.2385 11.4999 12.9998 11.4999C15.761 11.4999 17.9998 9.26113 17.9998 6.49988ZM25.4223 6.93363C24.1535 10.3586 19.046 12.7499 12.9998 12.7499C6.95476 12.7499 1.84601 10.3586 0.578506 6.93363C0.473506 6.65363 0.473506 6.34613 0.578506 6.06613C1.84601 2.64113 6.95476 0.249878 12.9998 0.249878C19.046 0.249878 24.1535 2.64113 25.4223 6.06613C25.526 6.34613 25.526 6.65363 25.4223 6.93363Z"
    fill="#171717"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13 7.75C13.69 7.75 14.25 7.19 14.25 6.5C14.25 5.81 13.69 5.25 13 5.25C12.31 5.25 11.75 5.81 11.75 6.5C11.75 7.19 12.31 7.75 13 7.75ZM13 4C14.3788 4 15.5 5.12125 15.5 6.5C15.5 7.87875 14.3788 9 13 9C11.6212 9 10.5 7.87875 10.5 6.5C10.5 5.12125 11.6212 4 13 4Z"
    fill="#171717"
  />
</template>
