<template>
  <BaseCard noPadding class="tw-my-4 tw-pb-10 tw-px-10 tw-pt-6">
    <div v-if="isInitialLoading">
      <div class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-h-80" aria-hidden="true" />
    </div>
    <div v-else-if="isStudioPlusCampaignsError" class="tw-text-red-700" v-html="$t('errors.wrong_data_from_app')" />

    <div v-else>
      <a
        class="hover:tw-cursor-pointer tw-font-bold tw-text-blue-700 tw-flex tw-py-2.5"
        @click="router.push({ name: 'settings-subscriptions' })"
        @keydown.enter="router.push({ name: 'settings-subscriptions' })"
      >
        ← {{ $t('setup_back') }}</a
      >

      <div class="tw-py-8 tw-border-b tw-border-gray-300">
        <h3 class="tw-text-xl tw-font-bold tw-mb-4">
          {{
            currentAccommodation?.name ?? $t('subscriptions.noAccommodationName', { accommodationId: accommodationId })
          }}
        </h3>
        <img v-if="productName !== 'PRO'" :src="getLogo(productName)" alt="Logo" class="tw-h-[28px]" />
        <p v-else class="tw-text-xl tw-font-bold">{{ $t('PRO Package') }}</p>
      </div>
      <SubscriptionDetailsStatus
        :subscription="currentCampaign"
        :accommodationId="accommodationId"
        :productName="productName"
        :hasActiveRC="hasActiveRC"
        :currentAccommodation="currentAccommodation"
      />
      <TransactionHistory :productType="productName" :accommodationId="accommodationId"/>
    </div>
  </BaseCard>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { useStudioPlusCampaigns } from '@/components/settings/subscriptions/queries'
import { useAccommodationsByOrgId } from '@/layouts/queries'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import SubscriptionDetailsStatus from '@/components/settings/subscriptions/SubscriptionDetailsStatus.vue'
import TransactionHistory from '@/components/settings/subscriptions/TransactionHistory.vue'

const rateConnectLogo = new URL('/src/assets/img/logos/rate-connect-logo.svg', import.meta.url)
const businessPlusLogo = new URL('/src/assets/img/logos/business-plus-logo.svg', import.meta.url)

const router = useRouter()
const store = useStore()

const { productName } = router.currentRoute.value.query
const accommodationId = Number(router.currentRoute.value.query?.accommodationId)
const selectedOrganisationId = computed(() => store.state.session.selectedOrganisation?.id)
const { accommodationsData } = useAccommodationsByOrgId(selectedOrganisationId)
const currentAccommodation = computed(() =>
  accommodationsData.value?.find(accommodation => accommodation.accommodationId === accommodationId)
)

const { studioPlusCampaigns, isStudioPlusCampaignsLoading, isStudioPlusCampaignsError, isStudioPlusCampaignsFetching } =
  useStudioPlusCampaigns(selectedOrganisationId)

const currentCampaign = computed(() =>
  studioPlusCampaigns.value.find(item => {
    return item.itemId === accommodationId && productName === item.type
  })
)

const hasActiveRC = computed(() => {
  const allAccommodationSubscriptions = studioPlusCampaigns.value.filter(
    item => item.itemId === currentCampaign.value.itemId
  )
  const hasRC = allAccommodationSubscriptions.find(sub => sub.type === 'RATE_CONNECT')
  if (hasRC) return true
  else return false
})

const isInitialLoading = computed(() => isStudioPlusCampaignsLoading.value || isStudioPlusCampaignsFetching.value)

const getLogo = product => {
  if (product === 'RATE_CONNECT') {
    return rateConnectLogo
  } else if (product === 'PLUS') {
    return businessPlusLogo
  }
}

onMounted(() => {
  if (!accommodationsData.value.find(item => item.accommodationId === accommodationId)) {
    router.push({ name: 'settings-subscriptions' })
  }
})
</script>
