<template>
  <div v-if="dataFetchedSuccessfully" class="tw-pb-8">
    <h2 class="tw-text-xl tw-font-bold tw-pb-2">{{ $t('rc_campaign_type_head') }}</h2>
    <div
      class="tw-relative tw-flex tw-items-center tw-flex-wrap md:tw-flex-nowrap tw-flex-row tw-w-full tw-text-gray-800 tw-text-left tw-border-0 tw-rounded-none tw-focus:outline-none"
    >
      <div class="tw-flex tw-items-start tw-flex-col tw-w-3/5 main-info md:tw-mb-0 tw-mb-5">
        <p
          v-if="currentCampaignType === 'cpc' && isItemNetCPAEligible"
          class="tw-text-sm tw-p-0 tw-m-0"
          v-html="$t('rc_campaign_ncpa_eligible')"
        />
        <p
          v-else-if="currentCampaignType === 'cpc' && !isItemNetCPAEligible"
          class="tw-text-sm tw-p-0 tw-m-0"
          v-html="$t('rc_campaign_ncpa_not_eligible')"
        />

        <p v-else class="tw-text-sm tw-p-0 tw-m-0" v-html="$t('rc_campaign_type_text')" />
      </div>
      <div class="tw-flex tw-w-1/5 tw-flex-col tw-items-start type-info">
        <p class="tw-text-base tw-font-bold">{{ $t(currentCampaignTypeTranslationKey) }}</p>
      </div>
      <div class="tw-flex tw-w-1/5 tw-justify-end action-btn" v-if="showChangeCampaignButton">
        <GhostButton size="medium" class="tw-px-9" @click="toggleChangeCampaignModal" :disabled="!currentRCPartner">{{
          $t('rc_campaign_type_button')
        }}</GhostButton>
      </div>
    </div>
    <div v-if="isCurrentCampaignCpa" class="tw-mt-8">
      <h2 class="tw-text-xl tw-font-bold tw-pb-2">{{ $t('rc_commission_header_settings') }}</h2>
      <div
        class="tw-flex-wrap md:tw-flex-nowrap tw-flex tw-items-center tw-flex-row tw-w-full tw-text-base tw-text-gray-800 tw-text-left"
      >
        <div class="tw-flex tw-items-start tw-flex-col tw-w-3/5 main-info md:tw-mb-0 tw-mb-5">
          <p class="tw-text-sm tw-p-0 tw-m-0">
            {{
              currentCampaignType === 'netCpa'
                ? $t('rc_pay_per_stay_commission_percentage_message', {
                    percent: CpaCommissionTranslationKeyPlaceholderValue,
                  })
                : $t('rc_cpa_commission_percentage_message', { percent: CpaCommissionTranslationKeyPlaceholderValue })
            }}
          </p>
        </div>
        <div class="tw-flex tw-w-1/5 tw-flex-col tw-items-start type-info">
          <p class="tw-text-base tw-font-bold">
            {{ Intl.NumberFormat(language, { style: 'percent' }).format(currentCampaignCpaCommission / 100) }}
          </p>
        </div>
        <div class="tw-flex tw-w-1/5 tw-justify-end action-btn">
          <GhostButton
            size="medium"
            :class="'tw-px-9 tw-py-2'"
            @click="toggleChangeCommissionModal"
            :disabled="currentRCPartner?.partnerStatus < 5 || !currentRCPartner"
            >{{ $t('rc_campaign_type_button') }}</GhostButton
          >
        </div>
      </div>
    </div>
    <ChangeCommissionModal v-if="isCurrentCampaignCpa" ref="changeCommissionModalRef" />
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

import GhostButton from '@/components/base/buttons/GhostButton.vue'
import ChangeCommissionModal from '@/components/rateConnect/settings/wizards/ChangeCommissionModal.vue'
import { useStartUp, useCampaigns, usePartners } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const { isSuccess: isStartUpSuccess, currentCampaignType, isItemNetCPAEligible } = useStartUp(selectedAccommodationId)
const { isSuccess: isCampaignsSuccess, campaignsData } = useCampaigns(selectedAccommodationId)
const { partnersData } = usePartners(selectedAccommodationId)
const { currentPartner } = useCampaigns(selectedAccommodationId)
const currentRCPartner = computed(() =>
  partnersData.value?.find(item => Number(item.partnerId) === Number(currentPartner.value))
)

const changeCommissionModalRef = ref(null)

const emit = defineEmits(['toggleChangeCampaignModal'])
const toggleChangeCampaignModal = () => emit('toggleChangeCampaignModal', 'settingsPage')

const language = computed(() => store.state.locale?.language)
const isCurrentCampaignCpa = computed(
  () => currentCampaignType.value === 'netCpa' || currentCampaignType.value === 'cpa'
)
const currentCampaignTypeTranslationKey = computed(() => {
  const campaignTypes = {
    cpa: 'rc_pay_per_booking',
    cpc: 'rc_pay_per_click',
    netCpa: 'rc_pay_per_stay',
  }
  return campaignTypes[currentCampaignType.value]
})
const currentCampaignCpaCommission = computed(() => campaignsData.value?.overall?.cpa)
const CpaCommissionTranslationKeyPlaceholderValue = computed(() =>
  Intl.NumberFormat(language.value, { style: 'percent' }).format(currentCampaignCpaCommission.value / 100)
)
const dataFetchedSuccessfully = computed(() => isStartUpSuccess.value && isCampaignsSuccess.value)

const toggleChangeCommissionModal = () => {
  changeCommissionModalRef.value?.showModal()
}

const showChangeCampaignButton = computed(() => {
  if (currentCampaignType.value === 'cpc' && !isItemNetCPAEligible.value) return false
  if (currentCampaignType.value === 'netCpa') return false
  return true
})
</script>
<style scoped>
.main-info {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 100%;
  }
}
.type-info,
.action-btn {
  @media (max-width: theme('screens.md')) {
    flex: 0 0 50%;
  }
}
</style>
