<template>
  <Notification
    :description="$t('notification_good_cpa_message')"
    :header="$t('notification_good_cpa_heading', { connectivityProvider: partnerName })"
    v-bind="$attrs"
  >
  </Notification>
</template>

<script setup>
import { computed } from 'vue'

import Notification from './Notification.vue'
import { useCampaigns, usePartners } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const { partnersData } = usePartners(selectedAccommodationId)
const { currentPartner } = useCampaigns(selectedAccommodationId)
const currentRCPartner = computed(() => partnersData.value?.find(item => item.partnerId === currentPartner?.value))

const partnerName = computed(() => currentRCPartner?.value?.partnerName)
</script>
