<template>
  <div>
    <SubscriptionPreview
      :subscription="subscription"
      :accommodationId="accommodationId"
      :accommodationName="accommodationName"
      @onViewDetails="onViewDetailsClick"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import SubscriptionPreview from './SubscriptionPreview.vue'

const props = defineProps({
  subscription: {
    type: Object,
    default: null,
  },
  accommodationId: {
    type: Number,
    default: null,
  },
  accommodationName: {
    type: String,
    default: '',
  },
})

const router = useRouter()
const productName = computed(() => props.subscription?.type)

const onViewDetailsClick = () => {
  router.push({
    name: 'settings-subscription-details',
    query: {
      accommodationId: props.accommodationId,
      productName: productName.value,
    },
  })
}
</script>
