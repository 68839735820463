<template>
  <div class="tw-relative">
    <label :for="id" :class="{ 'tw-mb-0': !label }" class="tw-w-full">
      <p class="tw-font-bold">{{ label }}</p>
      <div class="tw-relative">
        <input
          :id="id"
          :value="searchValue"
          :type="type"
          v-bind="$attrs"
          class="tw-appearance-none tw-border tw-w-full focus:tw-outline-0 tw-bg-white tw-text-gray-800 focus:tw-border-blue-800 tw-py-2 tw-px-3 tw-rounded-lg"
          :class="`${sizeClass} tw-border-gray-500`"
          @input="triggerUpdate"
          @focus="showResultsOnFocus"
        />
        <div
          v-if="showExampleText"
          class="tw-text-sm tw-bg-transparent tw-absolute tw-text-gray-300 tw-right-1 tw-top-[1px] tw-bottom-1 tw-left-[18px] tw-pr-4 tw-py-2 tw-rounded-lg"
          type="text"
        >
          {{ tempCurrentValue }}
        </div>
      </div>
    </label>
    <small v-if="error" class="tw-text-red-700">{{ error }}</small>

    <BaseCard v-if="isOpen" no-padding class="tw-max-h-[250px] tw-overflow-auto tw-absolute tw-right-0 tw-left-0">
      <ul v-if="isLoading">
        <li
          class="tw-p-2 hover:tw-bg-gray-100 tw-border-b tw-border-gray-100 tw-text-gray-300 tw-flex tw-justify-center tw-py-4"
        >
          <RCInlineLoader color="#ccc" />
        </li>
      </ul>
      <ul v-if="suggestedOptions.length > 0">
        <li
          v-for="(item, index) in suggestedOptions"
          :key="index"
          class="tw-p-2 hover:tw-cursor-pointer hover:tw-bg-gray-100 tw-border-b tw-border-gray-100"
          @click.stop="selectNewValue(item)"
          @keydown.enter="selectNewValue(item)"
        >
          {{ item.label }}
        </li>
      </ul>

      <ul v-else>
        <li class="tw-p-2 hover:tw-bg-gray-100 tw-border-b tw-border-gray-100 tw-text-gray-300">
          {{ $t('no_results') }}
        </li>
      </ul>
    </BaseCard>
  </div>
</template>
<script>
import debounce from 'lodash/debounce'
import BaseCard from '@/components/dashboard/BaseCard.vue'
import CountriesService from '@/services/CountriesService.js'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

export default {
  name: 'AutocompleteField',
  components: { BaseCard, RCInlineLoader },
  props: {
    id: {
      type: String,
      default: 'autocomplete',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    ariaLabel: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    error: {
      type: [Boolean, String],
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  data() {
    return {
      isOpen: false,
      suggestedOptions: [],
      searchValue: this.value,
      isLoading: false,
      tempCurrentValue: this.value,
      showExampleText: false,
    }
  },
  computed: {
    sizeClass() {
      if (this.size === 'medium') return 'tw-px-4 tw-box-size-36 tw-text-sm tw-rounded-lg '
      else return 'tw-px-6 tw-box-size-44 tw-text-base tw-rounded-lg'
    },
  },
  watch: {
    options: function (val, oldValue) {
      if (val.length !== oldValue.length) {
        this.suggestedOptions = val
        this.isLoading = false
      }
    },
    searchValue: function (val, oldValue) {
      if (oldValue === '') {
        this.showExampleText = false
      }
      if (val === '') {
        this.showExampleText = true
      }
    },
  },
  created() {
    this.search = debounce(this.search, 500)
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false
        this.searchValue = this.value
      }
    },
    selectNewValue(item) {
      this.$emit('onSelect', item)
      this.isOpen = false
      this.searchValue = item.name
    },
    showResultsOnFocus() {
      this.tempCurrentValue = this.value
      this.searchValue = ''
      this.search(this.tempCurrentValue)
      this.isOpen = true
    },
    search(value) {
      if (value.length > 0) {
        this.isLoading = true
        CountriesService.search({ searchTerm: value })
          .then(res => {
            this.suggestedOptions = res.data.map(city => {
              return {
                id: city.categoryId,
                name: city.name,
                label: city.name + ' (' + city.country + ')',
              }
            })
            this.isLoading = false
          })
          .catch(() => (this.isLoading = false))
      }
    },
    triggerUpdate(event) {
      this.searchValue = event.target.value
      this.search(event.target.value)
    },
  },
}
</script>
<style scoped>
label {
  @apply tw-mb-0;
}
</style>
