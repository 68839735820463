<template>
  <div class="tw-w-screen tw-h-screen tw-flex tw-justify-center tw-items-center">
    <div class="shadow tw-bg-white tw-max-w-screen-xl tw-mx-auto tw-rounded-lg tw-min-h-[373px]">
      <div
        class="tw-flex tw-flex-col landing:tw-flex-row tw-my-[30px] tw-px-10 tw-h-auto landing:tw-h-[290px] tw-flex tw-items-center tw-text-center landing:tw-text-left"
      >
        <div class="landing:tw-w-1/2 landing:tw-pr-20">
          <p class="tw-text-[56px] tw-font-bold tw-mb-7">{{ $t('business_studio_plus_notification_header') }}</p>
          <p class="tw-text-xl">{{ $t('business_studio_plus_notification_text') }}</p>
        </div>

        <div class="tw-max-h-[210px] tw-w-1/2">
          <img src="@/assets/img/hotels.svg" alt="Business Studio+ Announcement" height="210" width="594" />
        </div>
      </div>
      <div
        class="tw-bg-blue-200 tw-px-10 tw-h-[110px] tw-flex tw-items-center tw-justify-center landing:tw-justify-start tw-rounded-b-lg"
      >
        <p class="tw-text-2xl tw-font-bold">{{ $t('business_studio_plus_notification_banner') }}</p>
      </div>
    </div>
  </div>
</template>
<style scoped>
.shadow {
  box-shadow:
    0 2px 4px 0 rgba(63, 87, 189, 0.1),
    0 2px 10px 0 rgba(108, 112, 122, 0.15);
}
</style>
