<template>
  <section class="lg:tw-my-16 tw-mt-16 tw-mb-8">
    <div class="lg:tw-max-w-screen-landing md:tw-max-w-screen-md tw-px-8 lg:tw-px-0 tw-mx-auto tw-flex tw-flex-col">
      <div class="tw-items-end tw-mb-[53px] tw-hidden md:tw-flex">
        <img
          src="@/assets/img/landing/landing-bulb.svg"
          alt="trivago business studio hero image"
          class="lg:tw-h-[120px] lg:tw-w-[101px] md:tw-h-[100px] md:tw-w-[83px] tw-w-[50px] tw-h-[60px]"
        />
        <div class="tw-ml-6">
          <h1
            class="tw-text-[32px] tw-leading-[40px] tw-font-bold tw-text-black tw-mb-1"
            v-html="$t('tbs_mp_tbs_subtext')"
          />
          <p class="tw-text-black tw-text-sm md:tw-text-base lg:tw-text-xl tw-font-light">{{ $t('tbs_mp_key_message') }}</p>
        </div>
      </div>
      <div class="tw-items-center tw-mb-[53px] md:tw-hidden tw-flex tw-flex-col">
        <div class="tw-flex tw-items-center tw-mb-6 tw-justify-start tw-mr-auto">
          <img
            src="@/assets/img/landing/landing-bulb.svg"
            alt="trivago business studio hero image"
            class="md:tw-mb-[6px] tw-w-[50px] tw-h-[50px] tw-mr-2"
          />
          <h1
            class="lg:tw-text-[44px] md:tw-text-[32px] tw-font-bold tw-text-black tw-text-xl tw-mb-[-4px]"
            v-html="$t('tbs_mp_tbs_subtext')"
          />
        </div>

        <p class="tw-text-black tw-text-sm md:tw-text-base lg:tw-text-2xl">{{ $t('tbs_mp_key_message') }}</p>
      </div>

      <div class="tw-hidden lg:tw-flex tw-flex-col md:tw-flex-row">
        <div
          class="tw-bg-white tw-rounded-xl tw-relative tw-w-[316px] tw-mx-auto first:tw-ml-0 last:tw-mr-0"
          v-for="(card, index) in cardsWeb"
          :key="index"
        >
          <span
            class="tw-absolute tw-px-2 tw-text-sm tw-text-white tw-font-bold tw-bg-red-800 tw-rounded-tl-xl tw-rounded-br-xl"
            >{{ $t('tbs_mp_free_tag').toUpperCase() }}</span
          >

          <div class="tw-p-5 tw-pt-8 tw-flex tw-flex-col tw-h-full">
            <p class="tw-font-bold tw-text-2xl tw-pb-6 tw-grow">{{ $t(card.title) }}</p>
            <div class="tw-flex tw-gap-4 tw-items-end">
              <img :src="card.image" :alt="$t(card.title)" height="93" width="109" class="tw-mx-auto" />
              <p class="tw-font-bold tw-text-sm">{{ $t(card.description) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile version -->
    <div class="lg:tw-hidden tw-flex tw-flex-col md:tw-flex-row tw-w-vw tw-overflow-hidden carousel-container">
      <div class="inner carousel-mobile" ref="inner" :style="innerStyles">
          <div class="card" :id="card.id" v-for="(card, index) in cards" :key="index">
   
          <span
            class="tw-absolute tw-px-2 tw-text-sm tw-text-white tw-font-bold tw-bg-red-800 tw-rounded-tl-xl tw-rounded-br-xl"
            >{{ $t('tbs_mp_free_tag').toUpperCase() }}</span
          >
          <div class="tw-p-6 tw-pt-8 tw-flex tw-flex-col tw-h-full">

            <p class="tw-font-bold tw-text-2xl tw-pb-6 tw-grow">{{ $t(card.title) }}</p>
            <div class="tw-flex tw-gap-4 tw-items-end">
              <img :src="card.image" :alt="$t(card.title)" class="tw-mx-auto md:tw-mb-[2px]" />

              <p class="tw-text-gray-800 tw-text-sm tw-flex-1">{{ $t(card.description) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="lg:tw-hidden tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full tw-mt-8 tw-max-w-screen-md tw-px-8 tw-mx-auto"
    >
      <button
        v-for="(_, index) in cards"
        :key="index"
        class="tw-w-[12px] tw-mx-[5px] tw-h-[12px] tw-rounded-full tw-mb-6"
        :class="current === index  ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
      />

    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import TouchEvent from '@/utils/touchEvents.js'

const CardPropertyDetails = new URL('/src/assets/img/landing/landing_property_details.svg', import.meta.url)
const CardImageGallery = new URL('/src/assets/img/landing/landing_image_gallery.svg', import.meta.url)
const CardEssentialAnalytics = new URL('/src/assets/img/landing/landing_essential_analytics.svg', import.meta.url)


const cardsWeb = ref([
  {
    id: 'propertyDetails',
    title: 'tbs_mp_feature_1_header',
    description: 'tbs_mp_feature_1_subtext',
    image: CardPropertyDetails,
  },
  {
    id: 'imageGallery',
    title: 'tbs_mp_feature_2_header',
    description: 'tbs_mp_feature_2_subtext',
    image: CardImageGallery,
  },
  {
    id: 'essentialAnalytics',
    title: 'tbs_mp_feature_3_header',
    description: 'tbs_mp_feature_3_subtext',
    image: CardEssentialAnalytics,
  },
])
const cards = ref([
  {
    id: 'propertyDetails',
    title: 'tbs_mp_feature_1_header',
    description: 'tbs_mp_feature_1_subtext',
    image: CardPropertyDetails,
  },
  {
    id: 'imageGallery',
    title: 'tbs_mp_feature_2_header',
    description: 'tbs_mp_feature_2_subtext',
    image: CardImageGallery,
  },
  {
    id: 'essentialAnalytics',
    title: 'tbs_mp_feature_3_header',
    description: 'tbs_mp_feature_3_subtext',
    image: CardEssentialAnalytics,
  },
])

const touchEvent = ref(null)
const current = ref(1)
const timer = ref('')
const inner = ref(null)
const innerStyles = ref({})
const step = ref('')
const transitioning = ref(false)
const scrollCenter = ref(0)

onMounted(async () => {
  await nextTick()
  scrollToCenter()
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
})

onUnmounted(() => {
  clearInterval(timer)
  document.removeEventListener('touchend', handleSwipe)
})

const afterTransition = callback => {
  const listener = () => {
    callback()
    inner.value?.removeEventListener('transitionend', listener)
  }
  inner.value?.addEventListener('transitionend', listener)
}

const resetTranslate = () => {
  innerStyles.value = {
    transition: 'none',
    transform: `translateX(-${scrollCenter.value}px)`,
  }
}

const setStep = () => {
  const innerWidth = inner.value.scrollWidth
  const totalCards = cards.value.length

  step.value = `${(innerWidth / totalCards) + scrollCenter.value}`
}

const scrollToCenter = () => {
  const screenWidth =  window.document.querySelector('.carousel-container').clientWidth
  const carouselWidth = window.document.querySelector('.inner').scrollWidth
  const centerScroll = (carouselWidth - screenWidth)/2
  scrollCenter.value = centerScroll

  innerStyles.value = {
    transform: `translateX(-${scrollCenter.value}px)`,
  }
  setStep()
  autoplay()
}

const next = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveLeft()
  afterTransition(() => {
    const firstElementId = window.document.querySelector('.carousel-mobile').firstElementChild.id
    const item = window.document.getElementById(firstElementId);
    item.parentNode.appendChild(item);

    if (firstElementId === 'propertyDetails') current.value = 2
    if (firstElementId === 'imageGallery') current.value = 0
    if (firstElementId === 'essentialAnalytics') current.value = 1

    resetTranslate()
    transitioning.value = false
  })
}

const moveLeft = () => {
  innerStyles.value = {
    transform: `translateX(-${step.value}px)`,
  }
}

const prev = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveRight()
  afterTransition(() => {
    const lastElementId = window.document.querySelector('.carousel-mobile').lastElementChild.id
    const item = window.document.getElementById(lastElementId);
    item.parentNode.prepend(item);

    if (lastElementId === 'propertyDetails') current.value = 1
    if (lastElementId === 'imageGallery') current.value = 2
    if (lastElementId === 'essentialAnalytics') current.value = 0

    resetTranslate()
    transitioning.value = false
  })
}

const moveRight = () => {
  const baseStep = step.value - scrollCenter.value
  innerStyles.value = {
    transform: `translateX(${baseStep - scrollCenter.value}px)`,
  }
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 5000)
}

const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }
  touchEvent.value.setEndEvent(event)

  if (touchEvent.value.isSwipeRight()) {
    prev()
  } else if (touchEvent.value.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}
</script>
<style scoped>
.carousel-mobile {
  display: block;
  margin:auto;
  white-space: nowrap;
  transition: transform 1s;
  @media (max-width: theme('screens.md')) {
    width: auto;
  }
}

.card {
  @apply tw-bg-white tw-min-w-[300px] xs:tw-min-w-[336px] tw-min-h-[100%] tw-mx-[15px] tw-rounded-xl tw-relative;
  width: 336px;
  margin-right: 10px;
  display: inline-flex;
  white-space: wrap;
}
</style>
