<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.24817 16.7519L10.8957 10.5744L12.2194 11.8981L13.1019 12.7806L14.4257 14.1044L8.24817 16.7519ZM19.7219 1.74813L21.0457 3.07188L13.1019 11.0156L11.7782 9.69188L19.7219 1.74813ZM23.2519 5.27812L15.3082 13.2219L13.9844 11.8981L21.9282 3.95437L23.2519 5.27812ZM24.1344 6.16062C24.6219 5.67313 24.6219 4.88312 24.1344 4.39562L22.8107 3.07187L21.9282 2.18938L20.6044 0.865625C20.1169 0.378125 19.3269 0.378125 18.8394 0.865625L10.0132 9.69187L10.0194 9.69687C9.90942 9.80813 9.81192 9.93437 9.74817 10.0819L7.10067 16.2594C6.89942 16.7294 7.00442 17.2731 7.36567 17.6344C7.72692 17.9956 8.27067 18.1006 8.74067 17.8994L14.9182 15.2519C15.0657 15.1881 15.1919 15.0906 15.3032 14.9806L15.3082 14.9869L24.1344 6.16062Z"
    fill="#171717"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M19.5 12.5971V22.9996C19.5 23.6896 18.9387 24.2496 18.25 24.2496H3.25C2.56125 24.2496 2 23.6896 2 22.9996V15.8296V5.49963C2 4.81088 2.56125 4.24963 3.25 4.24963H13.58L14.83 2.99963H3.25C1.87 2.99963 0.75 4.11963 0.75 5.49963V22.9996C0.75 24.3809 1.87 25.4996 3.25 25.4996H18.25C19.63 25.4996 20.75 24.3809 20.75 22.9996V11.3471L19.5 12.5971Z"
    fill="#171717"
  />
</template>
