<template>
  <div class="tw-px-4 tw-mb-12 tw-mt-12">
    <BaseLink to="/rate-connect/no-campaign" class="tw-text-blue-700 tw-inline-block tw-font-bold tw-mb-5">
      ←
      {{ $t('setup_back') }}
    </BaseLink>
    <img src="@/assets/img/rate-connect/not-eligible.svg" alt="Not Eligible for Rate Connect" class="tw-mx-auto" />
    <h1 class="tw-mt-12 tw-text-3xl tw-text-center">{{ $t('rc_not_eligible_header') }}</h1>
    <p class="tw-text-gray-700 tw-text-xl tw-mt-6 tw-max-w-3xl tw-mx-auto tw-text-center">
      {{ $t('rc_not_eligible_text') }}
    </p>
    <Accordion :items="accordionItems" class="tw-max-w-2xl tw-mx-auto tw-mt-10">
      <template #content-0>
        <ProviderList />
      </template>
      <template #content-1>
        <div>
          <div class="accordion-content" v-html="$t('rc_not_eligible_issue_2_text')" />
          <p class="tw-font-bold">{{ $t('rc_not_eligible_points_header') }}</p>
          <ul class="tw-list-disc tw-ml-6 tw-mb-3">
            <li v-for="item in items" :key="item" class="tw-py-1">{{ $t(item) }}</li>
          </ul>
          <p v-html="$t('rc_not_eligible_closing_text')" />
        </div>
      </template>
      <template #content-2>
        <div class="accordion-content" v-html="$t('rc_not_eligible_issue_3_text')"></div>
      </template>
      <template #content-3>
        <div class="accordion-content" v-html="$t('rc_not_eligible_issue_4_text')"></div>
      </template>
    </Accordion>
  </div>
</template>

<script setup>
import Accordion from '@/components/base/Accordion.vue'
import BaseLink from '@/components/BaseLink.vue'
import ProviderList from '@/components/rateConnect/notEligible/ProviderList.vue'

const items = [
  'rc_not_eligible_point_1',
  'rc_not_eligible_point_2',
  'rc_not_eligible_point_3',
  'rc_not_elgibile_point_4',
]
const accordionItems = [
  {
    title: 'rc_not_eligible_issue_1_header',
  },
  {
    title: 'rc_not_eligible_issue_2_header',
  },
  {
    title: 'rc_not_eligible_issue_3_header',
  },
  {
    title: 'rc_not_eligible_issue_4_header',
  },
]
</script>
<style scoped>
.accordion-content :deep(p) {
  @apply tw-pb-3;
}

.accordion-content :deep(a),
p :deep(a) {
  color: theme('colors.blue.700');
  text-decoration: underline;
}
</style>
