<template>
  <div>
    <StudioPlusReactivationModal
      v-if="isReactivationModalOpen || (reactivationCompleted && accommodationId === reactivationAccommodationId)"
      :isOpen="isReactivationModalOpen"
      :expired="subscription.status === 'expired'"
      :cancelled="subscription.status === 'canceled'"
      :accommodationId="{ value: accommodationId }"
      :accommodationName="accommodationName"
      source="settings"
      @closeModal="closeReactivationModal"
    />
    <div
      class="tw-grid w-grid-cols-[1fr] md:tw-grid-cols-[1fr_1fr] lg:tw-grid-cols-[2fr_1.25fr_1fr_1.5fr_1.5fr_2fr] tw-gap-4 md:tw-gap-6"
    >
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full">
        <div class="tw-text-sm tw-font-bold">{{ $t('subscribed_property') }}</div>
        <div class="tw-text-sm tw-max-w-[250px] tw-text-ellipsis">{{ $t(accommodationName) }}</div>
      </div>
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full">
        <div class="tw-text-sm tw-font-bold">{{ $t('subscription_plan') }}</div>
        <div class="tw-text-sm">{{ $t(productName) }}</div>
      </div>
      <div class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full">
        <div class="tw-text-sm tw-font-bold">{{ $t('subscription_billing') }}</div>
        <p v-if="subscription.term === 'MONTHLY'" class="tw-text-sm">
          {{ $t('subscription_billing_monthly') }}
        </p>
        <p v-if="subscription.term === 'YEARLY'" class="tw-text-sm">
          {{ $t('subscription_billing_annually') }}
        </p>
        <p></p>
      </div>

      <!-- Subscription Active -->
      <div
        class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full"
        v-if="subscription.status === 'active'"
      >
        <div class="tw-text-sm tw-font-bold">
          {{
            isReactivatedActive ? $t('business_studio_plus_subscription_auto_renewal') : $t('subscriptions.nextPayment')
          }}
        </div>
        <div class="tw-text-sm">{{ nextPaymentDate }}</div>
      </div>
      <div
        class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full"
        v-if="subscription.status === 'active'"
      >
        <div class="tw-text-sm tw-font-bold">
          {{ isReactivatedActive ? $t('business_studio_plus_reactivation_date') : $t('subscription_start') }}
        </div>
        <div class="tw-text-sm">{{ startDate }}</div>
      </div>

      <!-- Subscription Cancelled -->
      <div
        class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full"
        v-if="subscription.status === 'canceled'"
      >
        <div class="tw-text-sm tw-font-bold">{{ $t('subscriptions.activeUntil') }}</div>
        <div class="tw-text-sm">{{ subscriptionEndDate ?? '-' }}</div>
      </div>
      <div
        class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full"
        v-if="subscription.status === 'canceled'"
      >
        <div class="tw-text-sm tw-font-bold">{{ $t('subscription_start') }}</div>
        <div class="tw-text-sm">{{ startDate }}</div>
      </div>

      <!-- Subscription Expired -->

      <div
        class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full"
        v-if="subscription.status === 'expired'"
      >
        <div class="tw-text-sm tw-font-bold">{{ $t('subscription_expiry_date') }}</div>
        <div class="tw-text-sm">{{ subscriptionEndDate ?? '-' }}</div>
      </div>
      <div
        v-if="subscription.status === 'expired'"
        class="tw-flex tw-items-left tw-flex-col tw-gap-1 md:tw-gap-2 tw-h-full"
      >
        {{ '' }}
      </div>

      <div
        class="tw-flex tw-flex-col tw-w-full md:tw-w-auto md:tw-flex-row tw-items-end tw-gap-3 md:tw-mt-4 lg:tw-justify-self-end lg:tw-mt-[-10px]"
      >
        <GhostButton size="medium" class="tw-w-full md:tw-w-auto" @click="onViewDetails">{{
          $t('rc_bookings_list_view')
        }}</GhostButton>
        <MainButton
          v-if="subscription.status !== 'active' && !productName.includes('PRO') && !subscription.hasActiveRC && isPlusOnboardingVisible"
          size="medium"
          class="tw-w-full md:tw-w-auto"
          @click="openReactivationModal"
        >
          {{ $t('business_studio_plus_reactivate_cta') }}
        </MainButton>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import StudioPlusReactivationModal from '@/components/settings/subscriptions/reactivation/StudioPlusReactivationModal.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
// Temporary Studio Plus Flag
import { isPlusOnboardingVisible } from '@/constants/studioPlusOnboarding.js'


const router = useRouter()
const store = useStore()
const emit = defineEmits(['onViewDetails'])
const onViewDetails = star => emit('onViewDetails', star)
const props = defineProps({
  subscription: {
    type: Object,
    default: null,
  },
  accommodationId: {
    type: Number,
    default: null,
  },
  accommodationName: {
    type: String,
    default: '',
  },
})

const reactivationCompleted = ref(false)

const isReactivationModalOpen = ref(false)
const reactivationAccommodationId = computed(() => store.state.studioPlus.reactivationAccommodationId)
const productName = computed(() => {
  if (props.subscription?.type === 'PLUS') return 'Business Studio+'
  else if (props.subscription?.type === 'PRO') return 'PRO Package'
  else return 'Rate Connect'
})
const startDate = computed(() => {
  return new Date(props.subscription?.subscriptionStartDate).toLocaleDateString(store.state.locale.language, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
})

const subscriptionEndDate = computed(() => {
  return props.subscription?.endDate !== null
    ? new Date(props.subscription?.endDate?.replace('@', ' ')).toLocaleDateString(store.state.locale.language, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      })
    : null
})

const nextPaymentDate = computed(() => {
  if (props.subscription.type === 'RATE_CONNECT') {
    const todayDayOfMonth = new Date().getDate()
    const month = todayDayOfMonth === 1 || todayDayOfMonth === 2 ? new Date().getMonth() : new Date().getMonth() + 1
    const day = 2
    const year = new Date().getFullYear()
    return new Date(year, month, day).toLocaleDateString(store.state.locale.language, {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })
  } else {
    return new Date(props.subscription?.nextPaymentDate).toLocaleDateString(store.state.locale.language, {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    })
  }
})

const isReactivatedActive = computed(() => {
  const startDate = new Date(props.subscription?.subscriptionStartDate)
  const currentDate = new Date()
  if (startDate !== undefined || startDate < currentDate) {
    return false
  } else {
    return true
  }
})

onMounted(() => {
  if (router.currentRoute?.value?.query?.reactivate === 'true') {
    reactivationCompleted.value = true
  } else {
    reactivationCompleted.value = false
  }
})

const openReactivationModal = () => {
  isReactivationModalOpen.value = true
}

const closeReactivationModal = () => {
  reactivationCompleted.value = false
  isReactivationModalOpen.value = false
}
</script>
