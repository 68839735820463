<template>
  <BaseCard
    v-if="slideCount > 0"
    class="tw-p-9 carousel tw-relative tw-max-w-screen-xxl tw-mx-auto tw-h-[450px] lg:tw-h-[340px]"
  >
    <div>
      <CarouselNav
        :slide-count="slideCount"
        :selected-slide="selectedSlide"
        :on-carousel-nav-click="onCarouselNavClick"
      />
    </div>
    <div class="md:tw-flex tw-flex-col md:tw-flex-row tw-gap-4">
      <div class="tw-grow tw-bg-transparent tw-w-full md:tw-w-1/2 lg:tw-w-2/3">
        <slot :selected-slide="selectedSlide" :selected-slide-name="selectedSlideName" />
      </div>
      <div class="carousel-icon tw-grow tw-w-1/2 lg:tw-w-1/3 tw-hidden md:tw-flex">
        <img
          v-show="selectedSlideName === 'my_property'"
          class="tw-hidden md:tw-inline-block tw-max-w-[224px] tw-mx-auto md:tw-mb-[-180px] lg:tw-mb-[-50px]"
          src="@/assets/img/carousel/slide_my_property.svg"
          alt="Rate Connect Image"
        />
        <img
          v-show="selectedSlideName === 'my_business_studio'"
          class="tw-hidden md:tw-inline-block tw-max-w-[303px] tw-mx-auto md:tw-mt-[60px] lg:tw-mt-[-25px]"
          src="@/assets/img/carousel/slide_my_business_studio.svg"
          alt="Rate Connect Image"
        />
        <img
          v-show="selectedSlideName === 'all_about_trivago'"
          class="tw-hidden md:tw-inline-block tw-max-w-[435px tw-mx-auto md:tw-mb-[-250px] lg:tw-mb-[-80px]"
          src="@/assets/img/carousel/slide_all_about_trivago.svg"
          alt="Rate Connect Image"
        />
      </div>
      <slot name="modal" :selected-slide="selectedSlide" :update-selected-slide="updateSelectedSlide" />
    </div>
  </BaseCard>
</template>
<script>
import { onMounted, onUnmounted, ref, computed } from 'vue'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import CarouselNav from './CarouselNav.vue'

export default {
  components: {
    BaseCard,
    CarouselNav,
  },
  name: 'Carousel',
  props: {
    slides: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    let timer
    const slideCount = computed(() => props.slides?.length)
    const selectedSlide = ref(0)
    const selectedSlideName = computed(() => props.slides?.[selectedSlide.value - 1]?.name)

    onMounted(() => {
      autoplay()
    })

    onUnmounted(() => {
      clearTimeout(timer)
    })

    const autoplay = () => {
      if (selectedSlide.value < slideCount.value) {
        selectedSlide.value++
      } else {
        selectedSlide.value = 1
      }
      timer = setTimeout(autoplay, 10000)
    }

    const onCarouselNavClick = value => {
      clearTimeout(timer)
      autoplay()
      updateSelectedSlide(value)
    }

    const updateSelectedSlide = value => {
      selectedSlide.value = value
    }

    return {
      slideCount,
      selectedSlide,
      selectedSlideName,
      onCarouselNavClick,
      updateSelectedSlide,
    }
  },
}
</script>
<style scoped>
.carousel {
  overflow: hidden;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 80%, rgba(229, 245, 255, 1) 80%);

  @media (min-width: theme('screens.md')) {
    max-height: 500px;
  }

  @media (max-width: theme('screens.md')) {
    background: theme('colors.white');
  }
}
.carousel-inner {
  @media (max-width: theme('screens.sm')) {
    min-height: 286px;
  }
}
.carousel-icon {
  max-width: 300px;
  margin: 0 auto;

  @media (min-width: theme('screens.md')) {
    margin: auto;
    max-width: 300px;
  }

  @media (min-width: theme('screens.lg')) {
    max-width: 530px;
  }
}
</style>
