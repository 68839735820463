<template>
  <section>
    <div 
      class="tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-mx-auto tw-py-8"
    >
      <div class="tw-flex tw-flex-col tw-items-center md:tw-items-start">
        <img :src="RateConnectLogo" alt="rate-connect-logo" class="tw-h-[20px] md:tw-h-[28px] tw-mb-2" />
        <h1
          class="tw-font-bold tw-text-black tw-text-[28px] lg:tw-text-[40px] tw-leading-[35px] lg:tw-leading-[50px] tw-text-center md:tw-text-left"
        >
          {{ $t('rc_mp_key_message') }}
        </h1>
      </div>
    </div>

    <!-- Web Version -->
    <div
      class="tw-flex tw-flex-col tw-w-full lg:tw-max-w-screen-landing md:tw-max-w-screen-md sm:tw-px-8 tw-px-4 lg:tw-px-0 tw-mx-auto"
    >
      <div class="lg:tw-flex tw-hidden tw-w-full tw-flex-wrap tw-justify-between tw-text-gray-800">
        <div
          v-for="(benefit, index) in webBenefits"
          :key="index"
          class="tw-bg-white tw-rounded-lg tw-flex tw-basis-[calc(50%-1rem)] tw-my-4 odd:tw-mr-4 even:tw-ml-4 tw-p-6 tw-gap-4"
        >
          <div class="tw-w-3/5">
            <p class="tw-font-bold tw-text-2xl tw-mb-4">{{ $t(benefit.title) }}</p>
            <p>{{ $t(benefit.text) }}</p>
          </div>
          <img :src="benefit.image" :alt="$t(benefit.title)" width="auto" height="165" class="tw-max-h-[165px]" />
        </div>
      </div>
    </div>

    <!-- Mobile Version -->
    <div class="lg:tw-hidden tw-flex tw-flex-col md:tw-flex-row tw-w-vw tw-overflow-hidden tw-text-gray-800">
      <div class="inner carousel-mobile " ref="inner" :style="innerStyles">
        <div
          v-for="(benefit, index) in benefits"
          :key="index"
          class="card"
          :class="getHeight()"
          :id="benefit.id"
        >
          <div class="md:tw-w-auto">
            <p class="tw-font-bold tw-text-xl md:tw-text-2xl tw-mb-2">
              {{ $t(benefit.title) }}
            </p>
            <p class="tw-text-sm md:tw-text-base">{{ $t(benefit.text) }}</p>
          </div>
          <img
            :src="benefit.image"
            :alt="$t(benefit.title)"
            width="auto"
            height="165"
            class="md:tw-max-h-[200px] tw-h-[120px]"
          />
        </div>
      </div>
    </div>

    <div class="lg:tw-hidden tw-flex tw-flex-row tw-items-center tw-justify-center tw-w-full tw-mt-5">
      <button
        v-for="(_, index) in benefits"
        :key="index"
        class="tw-w-[10px] tw-mx-[5px] tw-h-[10px] tw-rounded-full tw-mb-6"
        :class="current === index ? 'tw-bg-blue-800' : 'tw-bg-gray-300'"
      />
    </div>
  </section>
</template>
<script setup>
import { useStore } from 'vuex'
import { ref, onMounted, onUnmounted, watch, computed, nextTick } from 'vue'

import TouchEvent from '@/utils/touchEvents.js'

const RateConnectLogo = new URL('/src/assets/img/landing/landing_rate_connect.svg', import.meta.url)
const benefitOne = new URL('/src/assets/img/rate-connect-landing/Benefit_One.svg', import.meta.url)
const benefitTwo = new URL('/src/assets/img/rate-connect-landing/Benefit_Two.svg', import.meta.url)
const benefitThree = new URL('/src/assets/img/rate-connect-landing/Benefit_Three.svg', import.meta.url)
const benefitFour = new URL('/src/assets/img/rate-connect-landing/Benefit_Four.svg', import.meta.url)

const store = useStore()
const webBenefits = ref([
  {
    title: 'rc_mp_benefit_header_1',
    text: 'rc_mp_benefit_text_1',
    image: benefitOne,
  },
  {
    title: 'rc_mp_benefit_header_2',
    text: 'rc_mp_benefit_text_2',
    image: benefitTwo,
  },
  {
    title: 'rc_mp_benefit_header_3',
    text: 'rc_mp_benefit_text_3',
    image: benefitThree,
  },
  {
    title: 'rc_mp_benefit_header_4',
    text: 'rc_mp_benefit_text_4',
    image: benefitFour,
  },
])
const benefits = ref([
  {
    title: 'rc_mp_benefit_header_1',
    text: 'rc_mp_benefit_text_1',
    image: benefitOne,
    id: 'benefit_1',
  },
  {
    title: 'rc_mp_benefit_header_2',
    text: 'rc_mp_benefit_text_2',
    image: benefitTwo,
    id: 'benefit_2',
  },
  {
    title: 'rc_mp_benefit_header_3',
    text: 'rc_mp_benefit_text_3',
    image: benefitThree,
    id: 'benefit_3',
  },
  {
    title: 'rc_mp_benefit_header_4',
    text: 'rc_mp_benefit_text_4',
    image: benefitFour,
    id: 'benefit_4',
  },
])

const touchEvent = ref(null)
const current = ref(0)
const timer = ref('')
const isInitialSlide = ref(true)
const inner = ref(null)
const innerStyles = ref({})
const step = ref('')
const transitioning = ref(false)
const scrollCenter = ref(0)

const language = computed(() => store.state.locale.language)

watch(current, newCurrent => {
  if (newCurrent === 1 && isInitialSlide.value) {
    isInitialSlide.value = false
  }
})

const getHeight = () => {
  let heights = []
  if (language.value === 'ru-RU') {
    heights.push('tw-min-h-[250px] md:tw-min-h-[250px]')
  } else if (language.value === 'el-GR' || language.value === 'pt-BR' || language.value === 'de-DE') {
    heights.push('tw-min-h-[230px] md:tw-min-h-[230px]')
  } else if (language.value.includes('en')) {
    heights.push('tw-min-h-[160px] md:tw-min-h-[160px]')
  } else {
    heights.push('tw-min-h-[200px] md:tw-min-h-[200px]')
  }
  return heights.join(' ')
}

onMounted(async () => {
  await nextTick()
  setStep()
  autoplay()
  document.addEventListener('touchstart', event => {
    touchEvent.value = new TouchEvent(event)
  })

  document.addEventListener('touchend', handleSwipe)
})

onUnmounted(() => {
  clearInterval(timer)
  document.removeEventListener('touchend', handleSwipe)
})

const afterTransition = callback => {
  const listener = () => {
    callback()
    inner.value?.removeEventListener('transitionend', listener)
  }
  inner.value?.addEventListener('transitionend', listener)
}

const resetTranslate = () => {
  innerStyles.value = {
    transition: 'none',
    transform: `translateX(-${scrollCenter.value}px)`,
  }
}

const setStep = () => {
  const innerWidth = inner.value.scrollWidth
  const totalCards = benefits.value.length

  step.value = `${innerWidth / totalCards}`
}

const next = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveLeft()
  afterTransition(() => {
    const firstElementId = window.document.querySelector('.carousel-mobile').firstElementChild.id
    const item = window.document.getElementById(firstElementId)
    item.parentNode.appendChild(item)
    if (firstElementId === 'benefit_1') current.value = 1
    if (firstElementId === 'benefit_2') current.value = 2
    if (firstElementId === 'benefit_3') current.value = 3
    if (firstElementId === 'benefit_4') current.value = 0
    resetTranslate()
    transitioning.value = false
  })
}

const moveLeft = () => {
  innerStyles.value = {
    transform: `translateX(-${step.value}px)`,
  }
}

const prev = () => {
  if (transitioning.value) return
  transitioning.value = true
  moveRight()
  afterTransition(() => {
    const lastElementId = window.document.querySelector('.carousel-mobile').lastElementChild.id
    const item = window.document.getElementById(lastElementId);
    item.parentNode.prepend(item);

    if (lastElementId === 'benefit_1') current.value = 0
    if (lastElementId === 'benefit_2') current.value = 1
    if (lastElementId === 'benefit_3') current.value = 2
    if (lastElementId === 'benefit_4') current.value = 3

    resetTranslate()
    transitioning.value = false
  })
}

const moveRight = () => {
  const baseStep = step.value - scrollCenter.value
  innerStyles.value = {
    transform: `translateX(${baseStep - scrollCenter.value}px)`,
  }
}

const autoplay = () => {
  next()
  timer.value = setTimeout(autoplay, 5000)
}

const handleSwipe = event => {
  if (!touchEvent.value) {
    return
  }
  touchEvent.value.setEndEvent(event)

  if (touchEvent.value.isSwipeRight()) {
    prev()
  } else if (touchEvent.value.isSwipeLeft()) {
    next()
  }
  touchEvent.value = null
}
</script>
<style scoped>
.carousel-mobile {
  display: block;
  margin: auto;
  white-space: nowrap;
  transition: transform 2s;
  @media (max-width: theme('screens.md')) {
    width: auto;
  }
}
.card {
  @apply tw-bg-white tw-rounded-lg tw-flex tw-mx-4 tw-my-0 md:tw-my-4 tw-p-4 md:tw-p-6 md:tw-gap-4 tw-gap-2 tw-min-w-[300px] tw-w-[300px] xs:tw-min-w-[330px] xs:tw-w-[330px] md:tw-min-w-[450px] md:tw-w-[450px] tw-flex tw-items-start;
  margin-right: 10px;
  display: inline-flex;
  white-space: wrap;
}
</style>
